/* custom scrollbar */
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 16px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

.table-row-right {
  border-radius: 10px 0px 0px 10px;
  border: 1px solid red !important;
}

.select-option-fix {
  option {
    padding: 10px;
  }
}

.css-11r29a:active, .css-11r29a[data-active] {
  background: #F2F2F2 !important;
}


